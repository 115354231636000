class Bookings {
    private lv: LifeVine;
    private assignments: JQuery;
    private page: JQuery;
    private editor: JQuery;
    private list: JQuery;
    private view: JQuery;
    private dependents: JQuery;
    private locations: JQuery;
    private form: JQuery;
    private assignmentModal: JQuery;
    private requestSection: JQuery;

    private clientId: number|null;
    private currentId: number|null;

    private emptyBooking;

    constructor(lv: LifeVine) {
        this.lv = lv;
        this.page = jQuery('#page-bookings');
        this.editor = this.page.find('.booking_editor');
        this.form = this.editor.find('.booking_form');
        this.dependents = this.form.find('.booking_dependents');
        this.locations = this.form.find('.booking_locations');
        this.list = this.page.find('.booking_list');
        this.view = this.page.find('.booking_viewer');
        this.assignments = this.view.find('.booking_assignments');
        this.assignmentModal = this.page.find('#assignment_modal').modal();
        this.requestSection = this.form.find('.request-section');

        let start_time = new Date();
        start_time.setDate(start_time.getDate() + 2);
        start_time.setHours(18, 0, 0, 0);

        let end_time = new Date(start_time.getTime());
        // end_time.setDate(end_time.getDate() + 2);
        end_time.setHours(21, 30, 0, 0);

        this.emptyBooking = {
            status: 1,
            start_time: start_time,
            end_time: end_time,
            notes: '',
            staff_notes: '',
            admin_notes: '',
            request_first_available: true
        };

        this.form.on('submit', event => this.save(event));
        this.form.on('reset', event => {
            event.preventDefault();
            event.stopPropagation();
            history.back();
        });

        this.list.on('click', 'li', event => {
            event.preventDefault();
            event.stopPropagation();

            let $target = jQuery(event.target);
            let id = $target.closest('li').attr('data-id');
            let hash = '#page-bookings:' + (this.clientId ? `${this.clientId}:` : '') + id;

            // Edit button
            if ($target.closest('a').length > 0) {
                location.hash = hash + ':edit';
                return;
            }

            location.hash = hash;
        });

        this.assignments.find('.assignment_create').click(event => this.openAssignmentModel(event));
        this.assignmentModal.on('click', 'a', event => this.assignmentModalActions(event));
        this.assignments.on('click', 'button', event => {
            event.preventDefault();
            event.stopPropagation();

            let $target = jQuery(event.target);
            let id = parseInt($target.closest('li').attr('data-id'), 10);
            let data = {status: $target.attr('data-status')};
            this.getAssignments().save(id, data)
                .done((data) => {
                    Materialize.toast('Assignment updated', 2000);
                    this.refreshShow();
                })
                .fail(xhr => {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                });
        });

        this.requestSection.find('input').on('change', (event) => {
            console.log('blah');
            this.requestSection.find('select').prop('disabled', jQuery(event.target).prop('checked')).material_select();
        });

        this.form.on('change', '.quote-trigger', () => this.getQuote());
        Handlebars.registerPartial('SitterOptions', jQuery('#booking-sitter-options-partial').html());
    }

    public index(id?: number, options?: LifeVineBookingsOptions) {
        this.page.attr('data-mode', 'list');
        this.currentId = null;

        let ajax: LifeVineBookings;

        if (id) {
            this.clientId = id;
            ajax = this.lv.users().bookings(id);
            this.page.find('.booking_create').attr('href', `#page-bookings:${this.clientId}:create`);
        } else {
            this.clientId = null;
            ajax = this.lv.account().bookings();
            this.page.find('.booking_create').attr('href', `#page-bookings:create`);
        }

        if (!options) {
            options = {};
        }

        ajax.get(null, options)
            .then(bookings => {
                this.fillList(bookings);
            })
            .fail(xhr => {
                Materialize.toast('failed to retrieve bookings', 2000);
            });
    }

    public show(id: number, clientId?: number) {
        this.page.attr('data-mode', 'show');
        this.clientId = clientId || null;
        this.currentId = id;
        this.refreshShow();
    }

    private refreshShow() {
        this.getBookings().get(this.currentId)
            .then(booking => this.fillViewer(booking));
    }

    public edit(id: number, clientId?: number) {
        this.requestSection.hide();
        this.page.attr('data-mode', 'edit');
        this.clientId = clientId || null;
        this.currentId = id;

        this.getBookings().get(id)
            .then(booking => this.fillForm(booking));
    }

    public create(clientId?: number) {
        this.requestSection.show();
        this.requestSection.find('select').val('').html('').prop('disabled', true);
        this.page.attr('data-mode', 'create');
        this.clientId = clientId || null;
        this.currentId = null;
        this.fillForm(this.emptyBooking);
    }

    public save(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        let fd = getFormData(this.form);

        let start_time = Bookings.mergeDateTime(
            fd['start_date'],
            fd['start_hours'],
            fd['start_minutes'],
            fd['start_tod']
        );
        let end_time = Bookings.mergeDateTime(
            fd['end_date'],
            fd['end_hours'],
            fd['end_minutes'],
            fd['end_tod']
        );

        let submitData = {
            location_id: fd['location_id'],
            dependent_ids: fd['dependent_ids'],
            start_time: start_time.toYmdHis(),
            end_time: end_time.toYmdHis(),
            notes: fd['notes']
        };

        if (this.clientId) {
            submitData['status'] = fd['status'];
            submitData['staff_notes'] = fd['staff_notes'];
            submitData['admin_notes'] = fd['admin_notes'];
        }

        if (!this.currentId) {
            if (!this.requestSection.find('input').prop('checked')) {
                submitData['sitter_ids'] = this.requestSection.find('select').val();
                if (!submitData['sitter_ids']) {
                    Materialize.toast('Please select a sitter or choose "First Available"', 2000);
                    return;
                }
            }
        }

        let bookings = this.getBookings();

        if (this.currentId) {
            bookings.save(this.currentId, submitData)
                .then(() => {
                    Materialize.toast('Booking saved', 2000);
                    history.back();
                })
                .fail((xhr) => {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                });
        } else {
            bookings.create(submitData)
                .then(() => {
                    Materialize.toast('Booking created', 2000);
                    history.back();
                })
                .fail((xhr) => {
                    Materialize.toast(xhr.responseJSON.error, 2000);
                });
        }
    }

    getQuote() {
        let fd = getFormData(this.form);

        let start_time = Bookings.mergeDateTime(
            fd['start_date'],
            fd['start_hours'],
            fd['start_minutes'],
            fd['start_tod']
        );
        let end_time = Bookings.mergeDateTime(
            fd['end_date'],
            fd['end_hours'],
            fd['end_minutes'],
            fd['end_tod']
        );

        let submitData = {
            dependents: fd['dependent_ids'],
            start_time: start_time.toYmdHis(),
            end_time: end_time.toYmdHis()
        };

        this.getBookings().quote(submitData)
            .done(data => {
                jQuery('#booking_estimate').text(`$${data.quote} (${data.duration} hrs. with ${data.dependents} chn.)`);
            })
            .fail(xhr => {
                jQuery('#booking_estimate').text('N/A');
            });

        this.getBookings().availableSitters(null, submitData)
            .done(options => {
                let html = '';
                if (options.available.previous.length === 0 && options.available.other.length === 0) {
                    html = '<option value="">No available sitters at this time</option>';
                } else {
                    let template = Handlebars.compile(jQuery('#booking-sitters-template').html());
                    html = template(options);
                }
                this.requestSection.find('select').html(html).material_select();
            })
            .fail(xhr => {
                let html = '<option value="">N/A</option>';
                this.requestSection.find('select').html(html).material_select();
            });
    }

    private openAssignmentModel(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        this.getBookings().availableSitters(this.currentId)
            .done(options => {
                if (options.available.previous.length === 0 && options.available.other.length === 0) {
                    Materialize.toast('No available sitters found', 2000);
                    return;
                }
                let template = Handlebars.compile(jQuery('#booking-sitters-template').html());
                this.assignmentModal.find('#assignment_sitter_ids').html(template(options));
                this.assignmentModal.find('select').material_select();
                this.assignmentModal.modal('open');
            });
    }

    private assignmentModalActions(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (jQuery(event.target).is('#assignment_modal_cancel')) {
            this.assignmentModal.modal('close');
        } else {
            let data = getFormData(this.assignmentModal.find('form'));
            if (this.currentId) {
                this.getAssignments().create(data)
                    .done(() => {
                        this.refreshShow();
                        this.assignmentModal.modal('close');
                    })
                    .fail(xhr => {
                        Materialize.toast(xhr.responseJSON.error, 2000);
                    });
            }
        }

    }

    private fillList(bookings) {
        let template = Handlebars.compile(jQuery('#booking-list-template').html());
        let ul = this.list.find('ul').html(template(bookings));
    }

    private fillForm(booking) {
        let start_time = Bookings.splitDateTime(booking.start_time);
        let end_time = Bookings.splitDateTime(booking.end_time);

        if (this.clientId) {
            if (booking.client) {
                this.fillFormClientInfo(booking.client);
            } else {
                this.lv.users().get(this.clientId).then(client => {
                    this.fillFormClientInfo(client);
                });
            }
        }

        this.getLocations().get().done(locations => {
            let id = !!booking.location_id ? booking.location_id : locations[0].id;
            this.fillLocations(locations);
            setFormData(this.form, {location_id: id});
        });

        this.getDependents().get().done(dependents => {
            let ids = !!booking.dependent_ids ? booking.dependent_ids : [];
            this.fillDependents(dependents);
            setFormData(this.form, {'dependent_ids': ids});

            this.getQuote();
        });

        let fd = {
            start_date: start_time.date.toYmd(),
            start_hours: start_time.hours,
            start_minutes: start_time.date.getMinutes(),
            start_tod: start_time.tod,
            end_date: end_time.date.toYmd(),
            end_hours: end_time.hours,
            end_minutes: end_time.date.getMinutes(),
            end_tod: end_time.tod,
            notes: booking.notes
        };

        if (this.clientId) {
            fd['status'] = booking.status;
            fd['staff_notes'] = booking.staff_notes;
            fd['admin_notes'] = booking.admin_notes;
        }

        if (!this.currentId) {
            fd['request_first_available'] = booking.request_first_available;
        }

        setFormData(this.form, fd);

        this.form.find('select').material_select();
        Materialize.updateTextFields();
        this.form.find('.datepicker').pickadate({
            selectMonths: true,
            selectYears: 2,
            clear: '',
            min: new Date(),
            formatSubmit: 'yyyy-mm-dd',
            hiddenName: true
        });
    }

    private fillFormClientInfo(client) {
        this.page.find('#booking_client').text(client.first_name + ' ' + client.last_name);
    }

    private fillViewer(booking) {
        if (booking.hasOwnProperty('admin_notes')) {
            this.getAssignments().get()
                .done(assignments => this.fillAssignments(assignments));
        }

        booking['condition'] = {
            showEdit: (booking.status === 1 || this.clientId)
        };

        let time = booking.duration_h < 24 ?
            booking.start_time.split(' ')[0] + '<br>'
            + booking.start_time.split(' ')[1] + ' - ' + booking.end_time.split(' ')[1] :
            booking.start_time + ' - ' + booking.end_time;
        booking['time'] = new Handlebars.SafeString(time);

        booking['hash'] = '#page-bookings:' + (this.clientId ? `${this.clientId}:` : '') + booking.id + ':edit';

        let template = Handlebars.compile($('#booking-template').html());
        this.view.find('.card-content.booking-info')
            .html(template(booking))
            .attr('data-status', booking.status_text);
        return;
    }

    private fillAssignments(assignments) {
        let template = Handlebars.compile(jQuery('#booking-assignments-template').html());
        this.assignments.find('ul').html(template(assignments));
    }

    private fillDependents(dependents) {
        let template = Handlebars.compile(jQuery('#booking-dependents-template').html());
        this.dependents.html(template(dependents));
    }

    private fillLocations(locations) {
        let template = Handlebars.compile(jQuery('#booking-locations-template').html());
        this.locations.html(template(locations));
    }

    private getAssignments() {
        return this.lv.users().bookings(this.clientId).assignments(this.currentId);
    }

    private getBookings() {
        return this.clientId ?
            this.lv.users().bookings(this.clientId) :
            this.lv.account().bookings();
    }

    private getDependents() {
        return this.clientId ?
            this.lv.users().dependents(this.clientId) :
            this.lv.account().dependents();
    }

    private getLocations() {
        return this.clientId ?
            this.lv.users().locations(this.clientId) :
            this.lv.account().locations();
    }

    // private static formatDate(date: Date) {
    //     const days = {
    //         Sun: 'Sunday',
    //         Mon: 'Monday',
    //         Tue: 'Tuesday',
    //         Wed: 'Wednesday',
    //         Thu: 'Thursday',
    //         Fri: 'Friday',
    //         Sat: 'Saturday'
    //     };
    //
    //     let parts = date.toString().split(' ');
    //     let str = `${days[parts[0]]}, ${parseInt(parts[2])};
    // }

    public static splitDateTime(time: Date|string) {
        let a: Date = time instanceof Date ? time : new Date(time);
        let tod = 'am';
        let hours = a.getHours();
        if (hours === 0) {
            hours = 12;
        } else if (hours >= 12) {
            tod = 'pm';
            if (hours > 12) {
                hours = hours - 12;
            }
        }
        return {
            date: a,
            hours: hours,
            tod: tod
        };
    }

    public static mergeDateTime(ymd, hours, minutes, tod) {
        let d = new Date();
        let parts = ymd.split('-');
        d.setFullYear(parts[0], parseInt(parts[1], 10) - 1, parts[2]);

        hours = parseInt(hours, 10);
        if (tod === 'pm' && hours < 12) {
            hours = hours + 12;
        } else if (tod === 'am' && hours === 12) {
            hours = 0;
        }

        d.setHours(hours, minutes, 0, 0);
        return d;
    }
}

window.app.page('page-bookings', () => {
    return params => {
        if (!window.controllers.bookings) {
            window.controllers.bookings = new Bookings(window.lifeVine);
        }

        if (params === undefined) { // client list
            window.controllers.bookings.index();
            return;
        }

        let parts = params.split(':');

        if (isNaN(parts[0])) {
            if (parts[0] === 'create') { // client create
                window.controllers.bookings.create();
                return;
            }
        } else {
            if (parts.length === 1) { // client show
                window.controllers.bookings.show(parts[0]);
                return;
            }

            if (parts.length === 2) {
                if (!isNaN(parts[1])) { // admin show
                    window.controllers.bookings.show(parts[1], parts[0]);
                    return;
                }
                if (parts[1] === 'create') { // admin create
                    window.controllers.bookings.create(parts[0]);
                    return;
                }
                if (parts[1] === 'edit') { // client edit
                    window.controllers.bookings.edit(parts[0]);
                    return;
                }
                if (parts[1] === 'list') { // admin list
                    window.controllers.bookings.index(parts[0]);
                    return;
                }
                if (parts[1] === 'completed') { // admin list
                    window.controllers.bookings.index(parts[0], {status: [5]});
                    return;
                }

            }

            if (parts.length === 3 && !isNaN(parts[0]) && !isNaN(parts[1]) && parts[2] === 'edit') { // admin edit
                window.controllers.bookings.edit(parts[1], parts[0]);
                return;
            }
        }

        Materialize.toast('Could not find that page', 2000);
        location.hash = '#page-account';
    };
});